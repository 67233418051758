// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Phudu:wght@300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");

* {
  box-sizing: border-box;
}

nav {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
}

.case-studies-swiper {
  .swiper-pagination {
    display: none;
  }
  .case-logo {
    width: initial;
    height: initial;
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: yellow;
  padding: 50px;
  font-size: 20px;
}
.test {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  border: 2px dashed rgba(114, 186, 94, 0.35);
  height: 400px;
  background: rgba(114, 186, 94, 0.05);
}

.shark-1 {
  position: sticky;
  top: 0;
}

.shark-2 {
  position: sticky;
  top: 4rem;
}

.shark-3 {
  position: sticky;
  bottom: 1rem;
  align-self: flex-end;
}

.item img {
  border-radius: 16px;
  display: block;
  margin: 1rem auto;
  max-width: 100%;
}
@font-face {
  font-family: Saans;
  src: local("Saans Regular"), local("Saans-Regular"),
    url(./assets/fonts/Saans-Regular.woff2) format("woff2"),
    url(./assets/fonts/Saans-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Saans;
  src: local("Saans Medium"), local("Saans-Medium"),
    url(./assets/fonts/Saans-Medium.woff2) format("woff2"),
    url(./assets/fonts/Saans-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Reckless Neue";
  src: url(./assets/fonts/RecklessNeue-Regular.woff2) format("woff2"),
    url(./assets/fonts/RecklessNeue-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: Saans !important;
  font-family: Saans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Saans, sans-serif !important;
}

// SWIPER

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  // background: #fff;
  border-radius: 1rem;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.testimonials-swiper.swiper {
  padding-bottom: 3rem;
}

.testimonials-swiper.swiper .swiper-pagination {
  bottom: 0rem;
}

.testimonials-swiper.swiper .swiper-pagination-bullet {
  background: #03152b;
}

@media (max-width: 768px) {
  .testimonials-swiper.swiper .swiper-wrapper {
    width: 100%;
  }
}
